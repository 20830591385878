import Vue from "vue";
import Buefy from "buefy";
import "./assets/app.scss";
import router from "../router";

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "./i18n";

library.add(faCheck);
library.add(faExclamationTriangle);
library.add(faInfoCircle);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
}).$mount("#app");
