<template>
    <!-- form container starts here -->
    <div class="container" id="form_container">
      <section class="hero">
        <div class="hero-body top_menu">
          <p>
           <a href="https://www.beifahrer-register.de"><img src="logo.png" /></a>
           <a href="https://www.beifahrer-register.de">www.beifahrer-register.de</a>
           <span style="margin-left: 10px">|</span> <a href="/">Start</a>
         </p>
        </div>
      </section>
      <div class="agnes-center-all-one ">
        <section class="box agnes-login-form">
           <div class="has-text-centered mt-0 mb-5"><h4 class="subtitle is-5 formtitle">www.beifahrer-register.de</h4></div>
      
            <div class="field vemags_input_holder">
              <p class="control has-icons-right">
                <the-mask class="input is-medium vemags_nummer_input" mask="###########_A_##" placeholder="XXXXXXXXXXX_X_XX" type="vemags-nummer" v-model="vemags_nummer"></the-mask>
              </p>
              <i class="fa fa-check-circle beifahrer_check_icon check_vemags" aria-hidden="true" v-if="vn_validation.valid"></i>
              <span class="icon  is-small is-right info-icon">
                  <font-awesome-icon icon="info-circle" v-tooltip="{ content: $t('tooltip_one'), placement: 'right-center', classes: ['tooltip-dash'], offset: 20, delay: { show: 100, hide: 100, },}" />
              </span> 
              <p v-if="vn_validation.show_msg" class="form_error_msg">{{ vn_validation.error_msg }}</p>
            </div>
            <div class="field vemags_input_holder">
              <p class="control has-icons-right">
                <the-mask class="input is-medium vemags_nummer_input" mask="####" placeholder="XXXX" type="pin" v-model="pin"></the-mask>
              </p>
              <i class="fa fa-check-circle beifahrer_check_icon check_pin" aria-hidden="true" v-if="pin_valid.valid"></i>
              <span class="icon  is-small is-right info-icon">
                  <font-awesome-icon icon="info-circle" v-tooltip="{ content: $t('tooltip_two'), placement: 'right-center', classes: ['tooltip-dash'], offset: 20, delay: { show: 100, hide: 100, },}" />
              </span> 
              <p v-if="vn_validation.show_msg" class="form_error_msg">{{ pin_valid.error_msg }}</p>
            </div>

            <div class="field vemags_input_holder">
              <p class="control has-icons-right">
                <the-mask class="input is-medium vemags_nummer_input" mask="#.#" placeholder="X.X" type="pin" v-model="fahrtwegteil"></the-mask>
              </p>
              <i class="fa fa-check-circle beifahrer_check_icon check_pin" aria-hidden="true" v-if="fahrtwegteil_valid.valid"></i>
              <span class="icon  is-small is-right info-icon">
                  <font-awesome-icon icon="info-circle" v-tooltip="{ content: $t('tooltip_three'), placement: 'right-center', classes: ['tooltip-dash'], offset: 20, delay: { show: 100, hide: 100, },}" />
              </span> 
              <p v-if="vn_validation.show_msg" class="form_error_msg">{{ fahrtwegteil_valid.error_msg }}</p>
            </div>
        
            <div class="has-text-centered mt-4 mt-4">
              <button :disabled="checkValidation" class="button is-large is-fullwidth form-button" @click.prevent="beifahrerPrufen()">{{ $t('button_text') }}</button>
            </div>

             <p class="beifahrer-error-message" v-if="form_valid.show_msg">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span v-html="form_valid.error_msg"></span>
             </p>
            
             <!-- Language switcher starts here  -->
              <langswitcher></langswitcher>
             <!--  Language switcher ends here --> 

         </section> 
         <!-- Form Section ends here -->
         
    </div>
    <div class="agnes-center-all-two" style="margin-top:30px;">
       <section class="box agnes-login-form agnes-info-box">
           <div class="columns">
              <div class="column info-box-first is-one-fifth">
                   <span class="icon  is-small is-righ">
                        <font-awesome-icon icon="info-circle" />
                   </span>
              </div>
              <div class="column info-column is-four-fifths" v-html="$t('info_content')" ></div>
           </div>
       </section>
    </div>

    <footer class="footer" id="beifahrer-footer">
      <div class="buttons are-small form-buttons">
          <router-link class="button is-rounded footer_btn" to="/impressum">{{ $t('impressum_txt') }}</router-link>
          <router-link class="button is-rounded footer_btn" to="/datenschuz">{{ $t('datenschutz_txt') }}</router-link>
          <router-link class="button is-rounded footer_btn" to="/conditions">{{ $t('agb_txt') }}</router-link>
      </div>
    </footer>

  </div>
  <!-- form container ends here -->
</template>

<script>

import apiservice from '../apiservice';
import langswitcher from "./language.vue";

export default {
    components: { langswitcher },
    data() {

        return {

            vemagslength: 0,
            vemags_nummer: "",
            pin: "",
            fahrtwegteil: "",
            form_valid:
            {
               show_msg: false,
               error_msg: "" 
            },
            vn_validation:
            { 
              valid: false,
              show_msg: false,
              error_msg: ""
            },
            pin_valid:
            { 
              valid: false,
              show_msg: false,
              error_msg: ""
            }, 
            fahrtwegteil_valid:
            { 
              valid: false,
              show_msg: false,
              error_msg: ""
            }, 
          }
      }, 
      mounted() {
        console.log(process.env.VUE_APP_DOMAIN);
      },
      methods: {
    

      beifahrerPrufen()
      {   
         this.form_valid.show_msg = false; 
                   
         if( this.pin_valid.valid == true && this.vn_validation.valid == true && this.fahrtwegteil_valid.valid == true)
          { 
              
              const beifahrer = apiservice.beifahrer(this.vemags_nummer, this.pin, this.fahrtwegteil);
              beifahrer.then(result => 
              { 
                  console.log("--result--");
                  console.log(result);

                  if(result.status == 200)
                  { 
                    this.$router.push({ name: 'Dashboard', params: { authenticated: true, vemag: result.data, lang: this.$i18n.locale}})
                  }
                  else
                  { 
                    this.form_valid.show_msg = true;
                    this.authenticated = false;
                    //this.form_valid.error_msg = result;
                    this.form_valid.error_msg = this.$t("errors.form_valid_1");
                  } 

              })

          }
          else
          {
             this.form_valid.show_msg = true;
             this.form_valid.error_msg = this.$t("errors.form_valid_2");
          }
      }, 
      setLanguage()
      {
        this.$router.push('/form_en')
      }
    }, 
    computed:{
      checkValidation: function()
      {
         return this.pin_valid.valid == true && this.vn_validation.valid == true && this.fahrtwegteil_valid.valid ? false : true;
      }
    }, 
    watch: {
    vemags_nummer: function (val) {

      this.vemagslength = val.length;
      
      this.form_valid.show_msg = false;
      
      if(this.vemagslength == 14)
      {
         this.vn_validation.valid = true;
         this.vn_validation.show_msg = false;
         this.vn_validation.error_msg = "";
      }
      else
      {
         this.vn_validation.valid = false;
         this.vn_validation.show_msg = false;
         this.vn_validation.error_msg = this.$t("errors.vemags_nummer");
      }
    
    }, 
    pin: function (val) {

      this.form_valid.show_msg = false;   

      this.pinlength = val.length;

      if(this.pinlength == 4)
      {
         this.pin_valid.valid = true;
         this.pin_valid.show_msg = false;
         this.pin_valid.error_msg = "";
      }
      else
      {
         this.pin_valid.valid = false;
         this.pin_valid.show_msg = false;
         this.pin_valid.error_msg = this.$t("errors.pin_nummer");
      }
    
    },
    fahrtwegteil: function (val) {

      this.form_valid.show_msg = false;   
      if(val.length == 2)
        {
          this.fahrtwegteil_valid.valid = true;
          this.fahrtwegteil_valid.show_msg = false;
          this.fahrtwegteil_valid.error_msg = "";
        }
        else
        {
          this.fahrtwegteil_valid.valid = false;
          this.fahrtwegteil_valid.show_msg = false;
          this.fahrtwegteil_valid.error_msg = this.$t("errors.fahrtwegteil_nummer");
        }
    }
  }

}
</script>
