/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router"
Vue.use(VueRouter)


import Form from "../src/components/form.vue";
import Dashboard from "../src/components/dashboard.vue";
import Impressum from "../src/components/impressum.vue";
import Datenschuz from "../src/components/datenschuz.vue";
import ABG from "../src/components/agb.vue";


const routes = [

  { path: "/", component: Form, name: "Form"},
  { path: "/dashboard", component: Dashboard, name: "Dashboard", props: true },
  { path: "/impressum", component: Impressum, name: "Impressum" },
  { path: "/datenschuz", component: Datenschuz, name: "Datenschuz" },
  { path: "/conditions", component: ABG, name: "ABG" },

]

global.router = new VueRouter({
  routes, 
  mode: "history"
})

export default router;