<template>
    <div class="switcher_holder">
       <div class="lang_switcher switcher_de" v-if="$i18n.locale == 'de'">
            <div class="flag_holder"><img src="de.png"></div>
            <div class="text_holder">
              <p><label class="lang_name active">{{ $t("german") }}</label> | <label class="lang_name other_lang"><a href="#" @click.prevent="switchToEng();">{{ $t("english") }}</a></label></p>
            </div>
        </div>
        <div class="lang_switcher switcher_en" v-if="$i18n.locale == 'en'">
            <div class="flag_holder"><img src="eng.png"></div>
            <div class="text_holder">
              <p><label class="lang_name active">{{ $t("english") }}</label> | <label class="lang_name other_lang"><a href="#"  @click.prevent="switchToDe();">{{ $t("german") }}</a></label></p>
            </div>
        </div>
    </div>
    
</template>

<script>

export default
{
   name: 'langswitcher', 
   methods: {
              switchToEng(){ this.$i18n.locale = 'en';}, 
              switchToDe(){ this.$i18n.locale = 'de'; } 
    }
}

</script>