<template>
  <!-- form container starts here -->
  <div class="container" id="form_container">
    <section class="hero">
      <div class="hero-body top_menu">
        <p>
          <a href="https://www.beifahrer-register.de"><img src="logo.png" /></a>
          <a href="https://www.beifahrer-register.de">www.beifahrer-register.de</a>
          <span style="margin-left: 10px">|</span> <a href="/">Start</a>
        </p>
      </div>
    </section>
    <div class="tittle_holder">
      <h1 class="main_tittle">IMPRESSUM</h1>
    </div>
    <div
      class="agnes-center-all-one agnes-center-all-result agnes-login-form-data"
      style="padding: 20px !important"
    >
      <section class="box agnes-login-form-result">
        <p>ANGABEN GEMÄSS § 5 TMG<br>
        Sommer GmbH & Co. KG, Venhäuser Weg 11, 48477 Hörstel – Dreierwalde<br>
        VERTRETEN DURCH DIE GESCHÄFTSFÜHRERIN:<br>
        Helga Sommer<br>
        KONTAKT:<br>
        Telefon: +49 5978 9995-0 Telefax: +49 5978 9995-15 E-Mail: sommer@sommer-europe.com<br>
        REGISTEREINTRAG: <br>
        Eintragung im Handelsregister. Registergericht:Amtsgericht Steinfurt Registernummer: HRA 4294<br>
        UMSATZSTEUER:<br>
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 813 662 602<br>
        STREITSCHLICHTUNG<br>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: ec.europa.eu/consumers/odr/ <br>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahrenvor einer Verbraucherschlichtungsstelle teilzunehmen.<br>
        REDAKTIONELLE VERANTWORTUNG<br>
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br>
        Helga Sommer, Venhäuser Weg 11, 48477 Hörstel Dreierwalde</p>

        <p>IMPRESSUM</p>

        <p>ANGABEN GEMÄSS § 5 TMG<br>
        Sommer Digital Transport Solutions GmbH & Co. KG, Venhäuser Weg 11 48477, Hörstel – Dreierwalde<br>
        VERTRETEN DURCH DIE GESCHÄFTSFÜHRERIN:<br>
        Helga Sommer<br>
        KONTAKT:<br>
        Telefon: +49 5978 9995-0 Telefax: +49 5978 9995-15 E-Mail: sommer@sommer-europe.com<br>
        REGISTEREINTRAG:<br>
        Eintragung im Handelsregister. Registergericht:Amtsgericht Steinfurt Registernummer: HRA 7199<br>
        UMSATZSTEUER:<br>
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 313 462 105<br>
        STREITSCHLICHTUNG<br>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: ec.europa.eu/consumers/odr/<br>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br>
        REDAKTIONELLE VERANTWORTUNG<br>
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br>
        Helga Sommer, Venhäuser Weg 11, 48477 Hörstel Dreierwalde</p>

        <p>IMPRESSUM</p>

        <p>ANGABEN GEMÄSS § 5 TMG<br>
        Sommer Digital Transport Assistants GmbH, Am Keilenden Stein 5, 95180 Berg <br>
        VVERTRETEN DURCH DIE GESCHÄFTSFÜHRERIN:<br>
        Helga Sommer<br>
        KONTAKT:<br>
        Telefon: +49 5978 9995-0 Telefax: +49 5978 9995-15 E-Mail: sommer@sommer-europe.com<br>
        REGISTEREINTRAG:<br>
        Eintragung im Handelsregister. Registergericht:Amtsgericht Steinfurt Registernummer: HRA 7199<br>
        
        UMSATZSTEUER:<br>
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 313 462 105<br>
        STREITSCHLICHTUNG<br>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: ec.europa.eu/consumers/odr/<br>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br>
        REDAKTIONELLE VERANTWORTUNG<br>
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br>
        Helga Sommer, Venhäuser Weg 11, 48477 Hörstel Dreierwalde</p>
      </section>
    </div>

    <footer class="footer" id="beifahrer-footer">
      <div class="buttons are-small form-buttons">
        <router-link class="button is-rounded footer_btn" to="/impressum">{{
          content.impressum_txt
        }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/datenschuz">{{
          content.datenschutz_txt
        }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/conditions">{{
          content.agb_txt
        }}</router-link>
      </div>
    </footer>
  </div>
  <!-- form container ends here -->
</template>

<script>
export default {
  data() {
    return {
      content: {
        tooltip_one: "At vero eos et accusam et justo duo dolores et ea rebum",
        tooltip_two: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
        tooltip_three:
          "At vero eos et accusam et justo duo dolores et ea rebum",
        tooltip_four:
          "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum",
        impressum_txt: "Impressum",
        datenschutz_txt: "Datenschutz",
        agb_txt: "AGB",
        start_beifahrer: "Start co-driverr",
        ende_beifahrer: "End passenger",
        transpor_termin: "Transport appointment",
        gebuchte_stunden: "Booked hours",
        vemags_Bescheidversion: "VEMAGS notification version",
        geltungsdauer_von: "Validity from",
        geltungsdauer_bis: "Valid until",
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>
