<template>
  <!-- form container starts here -->
  <div class="container" id="form_container">
    <section class="hero">
      <div class="hero-body top_menu">
        <p>
          <a href="https://www.beifahrer-register.de"><img src="logo.png" /></a>
          <a href="https://www.beifahrer-register.de">www.beifahrer-register.de</a>
          <span style="margin-left: 10px">|</span> <a href="/">Start</a>
        </p>
      </div>
    </section>
    <div class="tittle_holder">
      <h1 class="main_tittle">AGB</h1>
    </div>
    <div
      class="agnes-center-all-one agnes-center-all-result agnes-login-form-data"
      style="padding: 20px !important"
    >
      <section class="box agnes-login-form-result">
        <p>
          Allgemeine Geschäftsbedingungen der Sommer Gruppe für Großraum- und
          Schwertransportdienstleistungen
        </p>
        <h1>1. Geltung</h1>
        <p>
          1.1 Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich
          gegenüber Unternehmen, juristischen Personen des öffentlichen Rechts
          oder öffentlich-rechtlichem Sondervermögen im Sinne von § 310 Absatz 1
          BGB (im Folgenden auch „Kunde“ genannt).
        </p>
        <p>
          1.2 Die Leistungen und Angebote der Sommer GmbH & Co. KG, der Sommer
          Digital Transport Solutions GmbH & Co. KG sowie der Sommer Digital
          Transport Assistants GmbH (im Folgenden „wir“ oder „uns“) erfolgen
          ausschließlich auf der Grundlage dieser Allgemeinen
          Geschäftsbedingungen. Diese gelten auch für alle künftigen Geschäfte
          mit dem Kunden, sofern es sich um Rechtsgeschäfte gleicher oder
          verwandter Art handelt. Für den Online-Vertrieb der Softwareprodukte
          ERNA, durch die Sommer Digital Transport Assistants GmbH unter
          www.sommer-store.com gelten gesonderte Bedingungen.
        </p>
        <p>
          1.3 Diese Allgemeinen Geschäftsbedingungen geltend für alle etwaigen
          mit der Ausführung des Auftrages beschäftigten Arbeitskräfte und
          Subunternehmer
        </p>
        <p>
          1.4 Entgegenstehende oder von diesen Geschäftsbedingungen abweichende
          Bedingungen des Kunden finden keine Anwendung, es sei denn, wir haben
          diesen Bedingungen ausdrücklich schriftlich zugestimmt. Dieses
          Zustimmungserfordernis gilt in jedem Fall, beispielsweise auch dann,
          wenn wir in Kenntnis der Allgemeinen Geschäftsbedingungen des Kunden
          dessen Leistungen vorbehaltlos annehmen.
        </p>
        <p>
          1.5 Hinweise auf gesetzliche Vorschriften haben nur klarstellende
          Bedeutung. Auch ohne eine derartige Klarstellung geltend daher die
          gesetzlichen Vorschriften, soweit sie in diesen Bedingungen nicht
          unmittelbar abgeändert oder ausgeschlossen werden.
        </p>
        <p>
          1.6 Wir speichern den Vertragstext und senden Ihnen die Bestelldaten
          und unsere AGB per E-Mail zu.
        </p>
        <h1>2. Angebot und Vertragsschluss</h1>
        <p>
          2.1 Unsere Angebote sind – vorbehaltlich der Ziff. 2.3 – freibleibend
          und unverbindlich, sofern auf dem Angebot nicht anders in Textform
          vermerkt. Ein Vertrag kommt erst durch die unsere Auftragsbestätigung
          in Textform (§ 126b BGB) oder mit der Ausführung des Auftrags
          zustande. Mündliche Abreden oder Zusagen bedürfen zu ihrer Wirksamkeit
          der Bestätigung in Textform durch uns. Wir sind an verbindliche
          Angebote maximal 4 Wochen gebunden.
        </p>
        <p>
          2.2 Leistungsdaten sind nur verbindlich, wenn dies ausdrücklich in
          Textform vereinbart wird.
        </p>
        <p>
          2.3 Für die Beauftragung mit einer BF4-Begleitung im Rahmen der „BF4
          MAP“ gilt Folgendes: Über den zur Verfügung gestellten Button
          „Bestellung“ kann der Kunde ein Angebot für die gewünschte
          BF4-Begleitung abgeben. Der Zugang dieser Bestellung wird von uns
          unverzüglich per E-Mail bestätigt. Der Vertrag über die BF4-Begleitung
          kommt sodann durch unsere Auftragsbestätigung zustande.
        </p>
        <p>
          2.4 Wir behalten uns an den dem Kunden zur Verfügung gestellten
          Unterlagen, wie z.B. an Angeboten und Kostenvoranschlägen, das
          Eigentum bzw. das Urheberrecht vor. Diese Dokumente unterliegen den
          Vertraulichkeitsbestimmungen entsprechend Ziff. 5. dieser Bedingungen.
        </p>
        <h1>3. Preise und Zahlung</h1>
        <p>
          3.1 Preise verstehen sich in EURO und in netto zuzüglich gesetzlich
          anfallender Umsatzsteuer. Etwaige Kosten für Versand, Zoll sowie
          Gebühren und andere etwaige öffentliche Abgaben werden gesondert
          berechnet.
        </p>
        <p>
          3.2 Rechnungsbeträge sind nach Rechnungseingang und Fälligkeit ohne
          Abzug zu zahlen. Der Abzug von Skonto ist nur bei schriftlicher
          besonderer Vereinbarung zulässig. Unsere Rechnungen sind, soweit bei
          Auftragserteilung nichts anderes vereinbart wurde, nach Erfüllung des
          Auftrags sofort nach Rechnungserhalt fällig. Der Verzug gemäß § 286
          Abs. 3 BGB bleibt unberührt.
        </p>
        <p>
          3.3 Bei ausländischen Kunden ohne Firmensitz oder Niederlassung im
          Inland sind wir berechtigt, vor Durchführung der vereinbarten
          vertraglichen Leistungen einen angemessenen Vorschuss auf die zu
          erwartenden Kosten oder Sicherheitsleistung zu verlangen.
        </p>
        <p>
          3.4 Der Kunde kann gegen unsere Forderungen nur mit unbestrittenen,
          von uns anerkannten und rechtskräftig festgestellten Forderungen oder
          mit Forderungen, die im Gegenseitigkeitsverhältnis zu unserer
          Forderung stehen, aufrechnen.
        </p>
        <p>
          3.5 Der Kunde ist zur Ausübung eines Zurückbehaltungsrechts nur
          befugt, soweit sein Gegenanspruch auf dem gleichen Vertragsverhältnis
          beruht.
        </p>
        <p>
          3.6 Soweit sich die Gebühren für Genehmigungen und Erlaubnisse gemäß
          §§ 29 und 46 StVO nach Auftragserteilung erhöhen, behalten wird uns
          vor, etwaige Mehrkosten zusätzlich in Rechnung zu stellen. Gleiches
          gilt, soweit neue Gebühren bereits vor Auftragserteilung in Kraft
          getreten sind, die zukünftige Verwaltungspraxis aber noch nicht
          feststeht. Sollte die weitere Auftragsausführung für
          Einzel-/Dauergenehmigungen und/oder Einzel-/Dauererlaubnisse aufgrund
          neuer gesetzlicher Regelungen oder einer neuen Verwaltungspraxis
          unmöglich werden, gelten Ziff. 4.4 und Ziff. 4.5 der Bedingungen
          entsprechend.
        </p>
        <h1>4. Leistungserbringung, Lieferzeit, Pflichten des Kunden</h1>
        <p>
          4.1 Fristen und Termine für Lieferungen und Leistungen gelten nur dann
          als Fixtermine, sofern sie als solche explizit vereinbart worden sind.
          Im Übrigen sind sämtliche angegebenen Termine und Zeiten, insbesondere
          für die Ankunft und eine Transitzeit – selbst wenn dies nicht
          ausdrücklich erwähnt wird – lediglich als geschätzte Termine und
          Zeiten zu verstehen.
        </p>
        <p>
          4.2 Verzögert sich die Leistungserbringung und haben wir diese
          Verzögerung zu vertreten, ist der Kunde nur zum Rücktritt berechtigt,
          sofern eine vom Kunden gesetzte angemessene Frist zur
          Leistungserbringung erfolglos verstrichen ist.
        </p>
        <p>
          4.3 Sind wir wegen Verzugs mit einer Lieferung oder Leistung oder
          wegen Unmöglichkeit zum Schadensersatz verpflichtet, so ist die
          Haftung auf Schadensersatz nach Maßgabe der Ziff.
        </p>
        <p>
          8.5. dieser Bedingungen beschränkt. Zumutbare Teillieferungen und
          –leistungen sind auch ohne gesonderte Vereinbarung zulässig. Dabei
          gilt jede Teillieferung und -leistung als selbständiges Geschäft.
        </p>
        <p>
          4.4 In Fällen von außerhalb unseres Einflussbereichs liegenden bzw.
          uns nicht zu vertretenden Ereignissen (Force Majeure), wie zum
          Beispiel Krieg, Naturkatastrophen, Arbeitskämpfen, behördliche
          Anordnungen oder neue gesetzliche Regelungen, sind wir von der
          rechtzeitigen Liefer- und Leistungsverpflichtung für die Dauer der
          Störung befreit. Liefer- und Leistungsfristen verschieben sich um die
          Dauer der Störung. Wir werden den Kunden über die Störung angemessen
          informieren. Ist ein Ende der Störung nicht absehbar oder dauert sie
          unter Berücksichtigung der vereinbarten Liefer- bzw. Leistungstermine
          und der beidseitigen Interessen unangemessen lange an und ist einer
          Partei das Festhalten am Vertrag infolge dessen nicht zumutbar, ist
          diese Partei berechtigt, vom Vertrag zurückzutreten. Weitergehende
          Ansprüche der Parteien, insbesondere Schadensersatzansprüche, sind
          ausgeschlossen.
        </p>
        <p>
          4.5 Soweit eine Partei gemäß Ziff. 4.4 oder Ziff. 9 vom Vertrag
          zurücktritt, bleiben wir berechtigt, für bereits erbrachte Leistungen
          sowie für den Ausfall und die Bereitstellung eine angemessene
          Entschädigung - beispielsweise Storno- und Bereitstellungskosten - zu
          verlangen.
        </p>
        <p>
          4.6 Grundsätzlich wird der Kunde die für die Lieferungen und
          Leistungen anzuwendenden Import- und Export-Vorschriften
          eigenverantwortlich beachten, insbesondere solche der USA. Der Kunde
          wird gesetzliche oder behördliche Verfahren im Zusammenhang mit
          grenzüberschreitenden Lieferungen oder Leistungen eigenverantwortlich
          abwickeln, es sei denn, es wurde etwas anderes ausdrücklich
          vereinbart.
        </p>
        <p>
          4.7 Der Kunde ist verpflichtet, vor Durchführung des Transportes eine
          Bescheinigung vorzulegen, in der die transportdurchführende Person/das
          transportdurchführende Unternehmen bestätigt, den Inhalt des
          Bescheides einschließlich der Bedingungen und Auflagen zur Kenntnis
          genommen zu haben. Wir werden dem Kunden eine entsprechende
          Blanko-Bescheinigung übersenden. Mit Inanspruchnahme und Beginn der
          jeweiligen Transportbegleitung versichert der Kunde, dass er, der
          Bescheidinhaber sowie die transportdurchführende Person/das
          transportdurchführende Unternehmen die Inhalte der jeweiligen
          Genehmigungen und Erlaubnisse für Großraum und Schwertransporte im In-
          und Ausland einschließlich der Bedingungen und Auflagen zur Kenntnis
          genommen haben. Der Kunde versichert ferner, dass er, der
          Bescheidinhaber sowie die transportdurchführende Person/das
          transportdurchführende Unternehmen alle Auflagen, Bedingungen und
          sonstige Nebenbestimmungen der Genehmigungen und Erlaubnisse beachten
          und allen daraus resultierenden Verpflichtungen nachkommen.
        </p>
        <p>
          4.8 Der Kunde nimmt ferner zur Kenntnis, dass eine Erlaubnis gemäß §
          29 Abs. 3 StVO mit Ablauf der Genehmigung nach § 70 StVZO ihre
          Gültigkeit verliert. Die Ablaufdaten sind vom Kunden zu beachten. Die
          Umsetzung und Erfüllung der Auflagen und Bedingungen einer Genehmigung
          bzw. Erlaubnis obliegt dem Kunden.
        </p>
        <p>
          4.9 Der Kunde ist verpflichtet, Genehmigungen nach § 70 StVZO sowie §
          30 Abs. 3 StVO im Original mitzuführen.
        </p>
        <h1>5. Vertraulichkeit</h1>
        <p>
          5.1 Beide Parteien verpflichten sich, sämtliche ihnen im Zusammenhang
          mit diesem Vertrag zugänglich werdenden Informationen des jeweils
          anderen Vertragspartners, die als vertraulich bezeichnet werden oder
          nach sonstigen Umständen als Geschäfts- oder Betriebsgeheimnisse eines
          Vertragspartners offensichtlich erkennbar sind, unbefristet geheim zu
          halten und sie – soweit nicht zur Erreichung des Vertragszwecks
          geboten – weder aufzuzeichnen noch weiterzugeben oder zu verwerten und
          auf diese den Umständen entsprechende angemessene
          Geheimhaltungsmaßnahmen anzuwenden. Sie werden durch geeignete
          vertragliche Abreden mit den für sie tätigen Arbeitnehmern und
          sonstigen Dritten sicherstellen, dass auch diese unbefristet jede
          eigene Verwertung oder unbefugte Aufzeichnung solcher Geschäfts- und
          Betriebsgeheimnisse unterlassen, soweit gesetzlich zulässig.
        </p>
        <p>
          5.2 Die vorstehenden Verpflichtungen gelten nicht für solche
          Informationen, für die der Vertragspartner, welche die Informationen
          empfängt nachweist, dass die Informationen: – ihm zum Zeitpunkt der
          Mitteilung bereits bekannt sind; –am Tage der Mitteilung bereits
          offenkundig sind oder danach offenkundig werden ohne Verletzung dieses
          Vertrages durch den empfangenden Vertragspartner; –ihm von einem
          Dritten mitgeteilt wurde, es sei denn, dem empfangenden
          Vertragspartner ist bekannt, dass der Dritte durch seine Mitteilung
          eine Geheimhaltungspflicht verletzt hat, die er gegenüber dem
          mitteilenden Vertragspartner übernommen hat; oder –von dem
          empfangenden Vertragspartner unabhängig und ohne die Nutzung von
          geheimen Informationen des mitteilenden Vertragspartner entwickelt
          wurde oder –aufgrund behördlicher oder gerichtlicher Anordnung
          zwingend offenzulegen sind.
        </p>
        <p>
          5.3 Diese Verpflichtung gilt auch nach Beendigung eines
          Einzelvertrages für einen Zeitraum von fünf Jahren fort.
        </p>
        <p>
          5.4 Den Vertragsparteien ist bekannt, dass eine elektronische und
          unverschlüsselte Kommunikation (z.B. per E-Mail) mit
          Sicherheitsrisiken behaftet ist. Bei dieser Art der Kommunikation
          werden sie daher keine Ansprüche geltend machen, die durch das Fehlen
          einer Verschlüsselung begründet sind, außer soweit zuvor eine
          Verschlüsselung vereinbart worden ist.
        </p>
        <h1>
          6. Leistungsumfang und Rechte an vertraglich überlassenen Leistungen
        </h1>
        <p>
          6.1 Wir erbringen Dienstleistungen zur Sicherung von Großraum- und
          Schwertransporten im öffentlichen Straßenverkehr nach Maßgabe der
          Richtlinien über die Durchführung von Großraum- und Schwertransporten
          (RGST 2013; VkBl. 2014, 154) und den Anordnungen und Auflagen der
          Erlaubnis- bzw. Genehmigungsbehörden in der jeweiligen
          Transporterlaubnis nach § 29 Abs. 3 StVO bzw. der Ausnahmegenehmigung
          nach § 46 Abs. 1, Nr. 5 StVO und § 70 Abs. 1 StVZO bzw.
          korrespondierender ausländischer Normen in Form eines Dienstvertrages.
          Wir schulden die übernommenen Dienste jedoch nicht höchstpersönlich
          und sind – soweit nicht ausdrücklich etwas anderes vereinbart wurde -
          berechtigt, ohne Zustimmung des Kunden Subunternehmer mit der
          Ausführung des übernommenen Auftrags zu beauftragen. Über den Einsatz
          von Subunternehmer ist der Kunde jedoch zu unterrichten.
        </p>
        <p>
          6.2 Darüber hinaus können wir als Geschäftsbesorger tätig werden und
          die Transporterlaubnis nach § 29 Abs. 3 StVO bzw. nach § 46 Abs. 1,
          Nr. 5 StVO und/oder § 70 Abs. 1 StVZO bzw. korrespondierender
          ausländischer Normen für Großraum- und Schwertransporte in Vollmacht
          und für Rechnung des Kunden einholen. Wir sind dagegen nicht
          berechtigt, selbst als Frachtführer oder Schwerlast-Spediteur
          aufzutreten. Gebühren und Kosten für behördliche Aufwendungen und
          Beschaffungskosten und Kosten, die durch behördliche Auflagen
          entstehen sowie Polizeibegleitgebühren und sonstige Kosten für
          behördlich angeordnete Sicherheitsvorkehrungen trägt, soweit nichts
          anderes vereinbart wurde, der Kunde. Wir übernehmen in diesem Falle
          jedoch keine Gewähr für die Erteilung der Transporterlaubnis bzw.
          Ausnahmegenehmigung oder deren rechtzeitiges Vorliegen.
        </p>
        <p>
          6.3 Im Rahmen der Fahrtwegeerkundung vor Antragstellung übernehmen wir
          auch keine Gewähr für die Geeignetheit des Fahrtweges und der
          Straßenbeschaffenheit hinsichtlich der besonderen Anforderungen des
          Transports. Die Fahrtwegeprüfung vor Fahrtantritt obliegt
          ausschließlich dem Kunden selbst. Ziff. 8.5 der Bedingungen bleibt
          unberührt.
        </p>
        <p>
          6.4 Übernehmen wir die Beschilderung von Straßenbaustellen unter
          Vorlage eines von der zuständigen Behörde genehmigten
          Verkehrszeichenplanes (vgl. § 45 Abs. 6 StVO), so werden wir als
          technischer Vollzugshelfer des Kunden tätig. Der Kunde haftet in
          diesem Fall für unsere Tätigkeit wie für einen Erfüllungsgehilfen.
          Ziff. 8.5 der Bedingungen bleibt unberührt.
        </p>

        <p>
          6.5 Wir verpflichten uns, nach entsprechender Anordnung der Erlaubnis-
          bzw. Genehmigungsbehörde, nur ordnungsgemäß ausgerüstete und
          kenntlichgemachte Schwertransport-Begleitfahrzeuge zu verwenden. Für
          den Fall, dass durch behördliche Anordnung ein
          Schwertransport-Begleitfahrzeug mit aufgesetzter
          Wechselverkehrszeichen-Anlage vorgeschrieben ist,verpflichten wir uns,
          nur Fahrzeuge zum Einsatz zu bringen, die gemäß „Merkblatt für die
          Ausrüstung der privaten Begleitfahrzeuge für Großraum- und
          Schwertransporte“ vom 30. Juni 2015 (VkBl. 2015, 404) ausgerüstet und
          anerkannt sind. Darüber hinaus verpflichten wir uns, in diesem Fall
          nur Begleit- und Fahrpersonal einzusetzen, das im Besitz eines
          gültigen Schulungsausweises für solche Begleitfahrten ist. Wir
          verfügen über Begleitfahrzeuge der Kategorien BF 2, BF 3, BF 3 Plus
          und BF 4. Sollte ein Begleitfahrzeug nach Beginn des Einsatzes durch
          Umstände, die wir nicht zu vertreten haben, nicht mehr der Ausrüstung
          gemäß „Merkblatt für die Ausrüstung der privaten Begleitfahrzeuge für
          Großraum- und Schwertransporte“ vom 30. Juni 2015 (VkBl. 2015, 404)
          entsprechen, haften wir für entstehende Verzögerungen oder Schäden
          gemäß Ziff. 8.5 nur beschränkt.
        </p>
        <p>
          6.6 Wir setzten zudem Begleit- und Fahrpersonal ein, dass von den
          Verkehrsbehörden nach Vorgabe eines Roadbooks/einer Roadmap/eines
          Regelplans als Verwaltungshelfer verpflichtet und unterwiesen werden
          kann. Ferner können unsere Begleit- und Fahrzeugführer nach einer
          Verpflichtung durch die Verkehrsbehörden für den jeweiligen Transport
          als Beliehener oder Hilfspolizei hoheitlich tätig werden. Auch bei
          einer hoheitlichen Tätigkeit bleiben wir berechtigt, dem Kunden die
          Tätigkeit unseres Personals in Rechnung zu stellen.
        </p>
        <p>
          6.7 Wir sind insbesondere bei technischen Defekten berechtigt,
          Schwertransport-Begleitfahrzeuge gleicher Bauart einzusetzen, die
          gemäß „Merkblatt für die Ausrüstung der privaten Begleitfahrzeuge für
          Großraum- und Schwertransporte“ vom 30. Juni 2015 (VkBl. 2015, 404)
          ausgerüstet und anerkannt sind. Soweit wir Subunternehmer einsetzten,
          sind diese dafür verantwortlich, dass die von ihnen eingesetzten
          Fahrzeuge und Fahrpersonal den gesetzlichen Anforderungen entsprechen.
          Insoweit haften wir nur beschränkte gemäß Ziff. 8.4 und 8.5. dieser
          Bedingungen.
        </p>
        <p>
          6.8 Einweisende Tätigkeit, Nachlenken der Transportfahrzeuge und Hilfe
          beim Be- und Entladen sind nicht Vertragsbestandteil der nach diesen
          Bedingungen geschlossenen Verträge. Eine Leistungspflicht unsererseits
          besteht daher nicht. Für einweisende Tätigkeit, Nachlenken der
          Transportfahrzeuge oder Hilfe beim Be- und Entladen haften wir, nach
          Maßgabe von Ziff. 8.5, nur beschränkt.
        </p>
        <p>
          6.9 Soweit für die vollständige Transportorganisation oder abgrenzbare
          Teilleistungen ein im Ausland ansässiges Partnerunternehmen beauftragt
          wird, übt dieses sämtliche Leistungen, wie beispielsweise
          polizeiersetzende Maßnahmen, im eigenen Pflichtenkreis und auf eigenes
          Risiko aus. Ziff. 8.4 und 8.5 der Bedingungen bleiben unberührt.
        </p>
        <p>
          6.10 In den Niederlanden, der Schweiz, Österreich oder Luxemburg
          können die notwendigen Erlaubnisse beantragen und Leistungen nach
          diesen Geschäftsbedingungen, sofern und soweit in diesen Ländern
          gesetzlich möglich, selbst erbringen.
        </p>
        <p>
          6.11 Die Nutzung der vertraglich überlassenen Daten aus
          3D-Rout-Scan-Messfahrten, insbesondere aber nicht ausschließlich der
          Laserpunktwolken (Lichtraumprofile), der Auswertung der Quer- und
          Längsschnitte, der Transportmodellierung, der Schleppkurvenanalyse,
          der Bilder sowie der daraus erstellten Streckenprotokolle und
          Roadbooks (die „vertraglich überlassenen Leistungen“) durch den Kunden
          ist lediglich für den Vertragszweck erlaubt. Eine Weitergabe dieser
          vertraglich überlassenen Leistungen an Dritte ist untersagt und darf
          nur nach Absprache mit uns erfolgen. Sämtliche Nutzungsrechte an den
          in diesem Abschnitt genannten vertraglich überlassenen Leistungen
          liegen bei uns und werden nicht auf den Kunden übertragen.
        </p>
        <p>
          6.12 Für die von uns erstellten Streckenprotokolle,
          Schleppkurven-Simulation und durchgeführten Streckenprüfungen gilt
          Folgendes: Die Empfehlungen auf der geprüften Strecke gelten
          vorbehaltlich der behördlichen Genehmigung. Die Ergebnisse der
          Streckenprüfung beruhen ausschließlich auf Daten des Lichtraumprofils,
          das mit einem Messsystem der Sommer Digital Transport Solutions GmbH &
          Co. KG aufgezeichnet wurde. Sollte das in Ausnahmefällen nicht der
          Fall sein, ist dies besonders gekennzeichnet. Für Aussagen über
          zulässige Transportgewichte und Achslasten kann eine kostenpflichtige
          Anfrage bei den zuständigen Straßenverkehrsbehörden sinnvoll sein. Wir
          bieten dies als gesonderte kostenpflichtige Zusatzleistung an. Die
          Ergebnisse und Empfehlungen der Streckenprüfung basieren auf einer
          Momentaufnahme der Strecke zum Zeitpunkt der Messfahrt. Sie gelten nur
          unter der Voraussetzung gleichbleibender Streckenbedingungen. Wir
          erheben keinen Anspruch auf Vollständigkeit und Umsetzbarkeit. Bei der
          Nutzung der Messergebnisse ist zu berücksichtigen, dass sich der
          tatsächliche Zustand im Laufe der Zeit durch Witterungseinflüsse,
          bauliche Maßnahmen und anders verändert. Die aus den Messungen
          abgeleiteten verkehrslenkenden Maßnahmen („VLM“) sind Empfehlungen.
          Die verbindlichen Vorgaben werden von den zuständigen
          Genehmigungsbehörden festgelegt. Für die Schleppkurven-Simulationen
          werden Modelle der Transporte verwendet. Die Modelle stellen eine
          Näherung der tatsächlichen Transporte dar. Die Ladung wird bei einer
          Schleppkurven-Analyse an der vorgesehenen Position auf dem Fahrzeug
          simuliert. Aufgrund der realen Eigenschaften der Fahrzeug- und
          Transporttechnik, der Möglichkeiten des Fahrers und des Fahrzeugs und
          anderer Faktoren wie der tatsächlichen Gewichte, Lastverteilungen und
          Materialeigenschaften ist die Berechnung von Schleppkurven nur eine
          Näherung. Für die Durchführung des Transportes ist es daher immer
          erforderlich, dass ein Sicherheitsabstand zu Hindernissen entlang des
          Fahrtweges zur Verfügung steht. Die empfohlenen VLM dienen dazu diesen
          Sicherheitsabstand bereitzustellen. Das transportdurchführende
          Unternehmen ist dazu verpflichtet, die Empfehlungen der
          Streckenprüfung vor Durchführung des Transportes zu prüfen. Dabei sind
          Unterschiede zwischen Modell und tatsächlichem Transport zu
          berücksichtigen und gegebenenfalls zusätzliche VLM entlang der Strecke
          vorzusehen. Für die erstellten Streckenprofile,
          Schleppkurvensimulationen und Streckenprüfungen haften wir nach
          Maßgabe von Ziff. 8.5 nur beschränkt.
        </p>
        <p>
          6.13 Soweit im Rahmen der Begleitanweisung Informationen über
          Baustellen eingeholte werden, handelt es sich um mündliche Auskünfte
          von Mitarbeitern der jeweiligen Autobahnmeistereien. Diese Auskünfte
          werden von uns nicht weiter geprüft. Für die Richtigkeit der Auskünfte
          stehen wir daher, vorbehaltlich der Regelung in Ziff. 8.5, nicht ein.
        </p>
        <p>
          6.14 Im Rahmen unseres Angebots „BF4 MAP“ bieten wir eine grafisch
          aufbereitete bundesweite Übersicht über die Strecken an, auf denen
          eine BF4-Begleitung durch uns bzw. unsere Subunternehmer möglich ist.
          BF4-Begleitung ist die polizeiersetzende Großraum- und
          Schwertransportbegleitung für den entgegenkommenden Verkehr außerhalb
          der Autobahn. Auf den in der „BF4 MAP“ angezeigten Strecken steht uns
          qualifiziertes Fachpersonal zur Verfügung, welches durch eine
          vorhergehende streckenspezifische Einweisung durch die örtliche
          Verkehrsbehörde oder die Polizei zur Durchführung einer BF4-Begleitung
          berechtigt ist. Das Fachpersonal kann nach einer Verpflichtung durch
          die Verkehrsbehörden für den jeweiligen Transport auch als Beliehener
          oder Hilfspolizei hoheitlich tätig werden (vgl. Ziff. 6.6). Für die
          Angaben im Rahmen der „BF4 MAP“ gilt Ziff. 2; es handelt es sich um
          grafisch aufbereitete unverbindliche Leistungsdaten, die erst in der
          Leistungsübersicht verbindlich konkretisiert werden. Für die Angaben
          im Rahmen der „BF4 MAP“ haften wir nach Maßgabe von Ziff. 8.5 nur
          beschränkt.
        </p>
        <h1>7. Versicherung</h1>
        <p>
          7.1 Wir verpflichten uns, für unsere Schwertransport-Begleitfahrzeuge
          als solche eine Kraftfahrzeug-Haftpflichtversicherung mit einer
          Deckungssumme von mindestens 100 Mio. Euro für Sachschäden und
          mindestens 8 Mio. Euro für Personenschäden je Schadensereignis unter
          Einschluss der besonderen Risiken aus der Verwendung des Fahrzeugs als
          SchwertransportBegleitfahrzeug abzuschließen.
        </p>
        <p>
          7.2 Wir verpflichten uns weiterhin, für unseren Betrieb eine
          kombinierte Betriebs- und Umwelthaftpflicht-Versicherung mit einer
          Deckungssumme von mindestens 20 Mio. Euro für Personenschäden, 1 Mio.
          Euro für Sachschäden und 25.000,00 Euro für Vermögensschäden je
          Schadensereignis unter Einschluss der typischen Tätigkeitsrisiken als
          Schwerlast-ServiceDienstleister abzuschließen.
        </p>
        <p>
          7.3 Soweit unser Begleit- und Fahrpersonal hoheitlich tätig wird,
          besteht eine Diensthaftpflichtversicherung mit einer Deckungssumme wie
          unter Ziff. 7.2.
        </p>
        <h1>8. Haftung</h1>
        <p>
          8.1 Wir haften für die Betriebs- und Verkehrssicherheit der von uns
          eingesetzten Schwertransport-Begleitfahrzeuge sowie für die
          Geeignetheit unseres Personals. Darüber hinaus haften wir für von uns
          verschuldete Verspätungs- und daraus resultierende Folgeschäden wegen
          Fahrtunterbrechung, verspäteter Anreise, Nichterscheinen am Abgangsort
          oder Fehldisposition maximal bis zum dreifachen Netto-Auftragswert der
          jeweiligen Begleitfahrt. Für sonstige Vermögensschäden haften wir
          maximal bis zu einem Betrag von 25.000,00 Euro je Schadensereignis.
          Ziff. 8.5 der Bedingungen bleibt unberührt.
        </p>
        <p>
          8.2 Wir haften nicht für eine Transportunterbrechung infolge höherer
          Gewalt, insbesondere aber nicht ausschließlich Stau, Nebel, Glatteis,
          Streik oder sonstige unverschuldete Transportunterbrechungen, die
          durch eine Stilllegung des Transportfahrzeuges verursacht wurden.
          Darüber hinaus haften wir nicht für die ordnungsgemäße Sicherung des
          Schwertransportfahrzeuges selbst (z.B. Richtlinien über die
          Kenntlichmachung überbreiter und überlanger Straßenfahrzeuge sowie
          bestimmter hinausragender Ladungen in der jeweils gültigen Fassung)
          sowie für die Einhaltung der Ausnahmebestimmungen für die Abweichung
          von den Bau- und Betriebsvorschriften für das Schwertransportfahrzeug.
          Wir haften ferner nicht für eine betriebsund verkehrssichere Verladung
          des Ladegutes auf dem Schwertransport-Fahrzeug sowie für Güterschäden,
          die in der Obhut des Kunden entstehen. Ziff. 8.5 der Bedingungen
          bleibt unberührt.
        </p>
        <p>
          8.3 Soweit unserer Begleit- und Fahrzeugführer hoheitlich tätig
          werden, gelten im Schadensfall die Grundsätze der Staatshaftung nach
          Art. 34 GG in Verbindung mit § 839 BGB. Unsere Haftung ist bei einer
          hoheitlichen Tätigkeit unserer Begleit- und Fahrzeugführer nach
          Maßgabe vom Ziff. 8.5 beschränkt.
        </p>
        <p>
          8.4 Soweit wir zur Auftragsausführung im Inland oder Ausland ein
          Partnerunternehmen vermitteln, haften wir für deren Vertragsausführung
          oder für von Partnerunternehmen verursachte Schäden nach Maßgabe von
          Ziff. 8.5 nur beschränkt.
        </p>
        <p>
          8.5 Unsere Haftung ist nach Maßgabe dieser Regelung beschränkt. Die
          Haftungsbefreiungen und Haftungsbeschränkungen nach diesen Bedingungen
          gelten nicht, soweit entstehende Schäden auf einer Verletzung einer
          wesentlichen Vertragspflicht oder auf einem vorsätzlichen oder grob
          fährlässigen Verhalten durch uns, unserer gesetzlichen Vertreter oder
          Erfüllungsgehilfen beruhen. Wird eine wesentliche Vertragspflicht
          leicht fahrlässig verletzt, so ist unsere Haftung auf den
          vorhersehbaren vertragstypischen Schaden begrenzt. Eine wesentliche
          Vertragspflicht ist bei Verpflichtungen gegeben, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrages erst möglich macht oder auf
          deren Einhaltung der Kunden vertraut hat und vertrauen durfte. Eine
          darüber hinausgehende Haftung auf Schadensersatz ist ausgeschlossen.
          Die Haftung wegen schuldhafter Verletzung von Leben, Körper oder
          Gesundheit nach den gesetzlichen Bestimmungen und eine Haftung nach
          dem Produkthaftungsgesetz bleiben unberührt.
        </p>
        <h1>9. Ausschluss der Leistungspflicht und Rücktritt</h1>
        <p>
          9.1 Wir sind berechtigt, unter Ausschluss jeglicher
          Schadensersatzansprüche die Schwertransport-Begleitung solange zu
          verweigern, bis eine gültige Erlaubnis bzw. Ausnahmegenehmigung für
          den Großraum- und/oder Schwertransport vorliegt, oder wenn aufgrund
          konkreter Anhaltspunkte zu besorgen ist, dass bei
          Transportdurchführung gegen behördliche Auflagen oder Anordnungen der
          Erlaubnis- bzw. Genehmigungsbehörde verstoßen werden muss. Der Kunden
          ist verpflichtet, vor Transportbeginn, uns auf Verlangen Einsicht in
          die behördlichen Erlaubnisse und Ausnahmegenehmigungen zu gewähren.
          Wir sind insbesondere berechtigt, unter Ausschluss jeglicher
          Schadensersatzansprüche vom Vertrag zurückzutreten und den
          Schwertransport-Begleitschutz zu verweigern, wenn nicht sichergestellt
          ist, dass der Führer oder der Beifahrer des Großraum- und/oder
          Schwertransports sachkundig und der deutschen Sprache hinreichend
          mächtig ist.
        </p>
        <p>
          9.2 Darüber hinaus sind wir berechtigt, unter Ausschluss jeglicher
          Schadensersatzansprüche den Schwertransport-Begleitschutz zu
          unterbrechen, wenn aufgrund konkreter Anhaltspunkte die Besorgnis
          besteht, dass bei Fortsetzung des Transports eine über das bei
          Transportbeginn vorhersehbare Maß deutlich hinausgehende Gefahr für
          die Sicherheit und Leichtigkeit des öffentlichen Straßenverkehrs zu
          befürchten ist oder wesentliche Schäden an Sachen Dritter oder an
          unseren Sachen und/oder fremden oder eigenen Vermögenswerten drohen.
          Bei einer Unterbrechung des Schwertransport-Begleitschutzes ist jedoch
          sicherzustellen, dass der Transport nicht ungesichert auf öffentlichen
          Straßen abgestellt wird.
        </p>

        <p>
          9.3 Bei Wartezeiten und Verzögerungen aufgrund des Nichtvorliegens
          einer gültigen Transporterlaubnis bzw. Ausnahmegenehmigung hat der
          Kunden uns ein angemessenes Standoder Wartegeld zu ersetzen, sofern
          wir die Verzögerung nicht zu vertreten haben. Dasselbe gilt für
          Wartezeiten oder Verzögerungen, die aufgrund besonderer Anordnung des
          Kunden anfallen. Bei einer nicht nur vorübergehenden
          Transportstillegung sind wir berechtigt, unter Ausschluss von
          Schadensersatzansprüchen vom Vertrag zurückzutreten und die
          Begleitfahrt abzubrechen, um etwaige Folgeaufträge rechtzeitig
          wahrnehmen zu können.
        </p>
        <p>
          9.4 Die Regelungen unter Ziff. 4.5 und 5 der Geschäftsbedingungen
          bleiben bei einem Rücktritt nach diesem Abschnitt unberührt
        </p>
        <h1>10. Erfüllungsort, anwendbares Recht und Gerichtsstand</h1>
        <p>
          10.1 Erfüllungsort für sämtliche Leistungen unsererseits und für die
          sonstigen Vertragsverpflichtungen beider Parteien ist, sofern nichts
          Abweichendes vereinbart wurde, Hörstel-Dreierwalde, Bundesrepublik
          Deutschland.
        </p>
        <p>
          10.2 Der Einzelvertrag und diese Geschäftsbedingungen sowie die
          gesamten Rechtsbeziehungen zwischen dem Kunden und uns unterliegen dem
          Recht der Bundesrepublik Deutschland unter Ausschluss von
          UN-Kaufrecht. Eine Anwendung der CMR bleibt unberührt.
        </p>
        <p>
          10.3 Bei allen sich aus oder im Zusammenhang mit diesem
          Vertragsverhältnis ergebenden Streitigkeiten ist Münster der
          Gerichtsstand. Wir sind jedoch berechtigt, den Kunden auch an seinem
          Geschäftssitz zu verklagen.
        </p>
        <p>
          10.4 Änderungen und Ergänzungen der Allgemeinen Geschäftsbedingungen
          und des Vertrags können nur schriftlich vereinbart werden. Das gilt
          für die Änderung dieser Schriftformklausel gleichfalls.
        </p>
        <p>
          10.5 Sind oder werden einzelne Bestimmungen des Einzelvertrages oder
          der Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam, so
          wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt.
        </p>
        <p>Stand, 01.01.2021</p>
      </section>
    </div>
    <footer class="footer" id="beifahrer-footer">
      <div class="buttons are-small form-buttons">
        <router-link class="button is-rounded footer_btn" to="/impressum">{{
          content.impressum_txt
        }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/datenschuz">{{
          content.datenschutz_txt
        }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/conditions">{{
          content.agb_txt
        }}</router-link>
      </div>
    </footer>
  </div>
  <!-- form container ends here -->
</template>

<script>
export default {
  data() {
    return {
      content: {
        tooltip_one: "At vero eos et accusam et justo duo dolores et ea rebum",
        tooltip_two: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
        tooltip_three:
          "At vero eos et accusam et justo duo dolores et ea rebum",
        tooltip_four:
          "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum",
        impressum_txt: "Impressum",
        datenschutz_txt: "Datenschutz",
        agb_txt: "AGB",
        start_beifahrer: "Start co-driverr",
        ende_beifahrer: "End passenger",
        transpor_termin: "Transport appointment",
        gebuchte_stunden: "Booked hours",
        vemags_Bescheidversion: "VEMAGS notification version",
        geltungsdauer_von: "Validity from",
        geltungsdauer_bis: "Valid until",
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>
