<template>
  <!-- form container starts here -->
  <div class="container" id="form_container">
    <section class="hero">
      <div class="hero-body top_menu">
        <p>
          <a href="https://www.beifahrer-register.de"><img src="logo.png" /></a>
          <a href="https://www.beifahrer-register.de">www.beifahrer-register.de</a>
          <span style="margin-left: 10px">|</span> <a href="/">Start</a>
        </p>
      </div>
    </section>
    <div class="agnes-center-all-one agnes-center-all-result">
      <section class="box agnes-login-form-result">
        <div class="has-text-left mt-0 mb-5">
          <h4 class="subtitle is-5 heading_title">www.beifahrer-register.de</h4>
        </div>

        <div class="columns columns-dash">
          <!-- Columns holder -->

          <div class="column is-two-thirds form-holder">
            <!-- Left column -->
            
            <div class="field">
              <label class="label field-label">{{ $t('vemags_nb') }}</label>
              <div class="control">
                <input
                  class="input dashboard_input"
                  type="text"
                  placeholder="XXXXXXXXX_XX_X"
                  v-model="vemags.vemags_nb"
                  disabled
                />
              </div>
            </div>
            <div class="field">
            <!-- EMPTY -->
            </div>
            <div class="field">
              <label class="label field-label">{{ $t('supplier') }}</label>
              <div class="control">
                <input
                  class="input dashboard_input"
                  type="text"
                  placeholder="-"
                  v-model="vemags.supplier"
                  disabled/>
              </div>
            </div>
            <div class="field">
                <label class="label field-label">{{ $t('system') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.system"
                    disabled
                  />
                </div>
           </div> 
           <div class="field">
              <label class="label field-label">{{ $t('responsible_person') }}</label>
              <div class="control">
                <input
                  class="input dashboard_input"
                  type="text"
                  placeholder="-"
                  v-model="vemags.responsible_person"
                  disabled
                />
              </div>
           </div>
           <div class="field">
                <label class="label field-label">{{ $t('responsible_tel') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.responsible_tel"
                    disabled
                  />
                </div>
          </div>
           
           <div class="field">
                  <label class="label field-label">{{ $t('approval_start') }}</label>
                  <div class="control">
                    <input
                      class="input dashboard_input"
                      type="text"
                      placeholder="-"
                      v-model="vemags.approval_start"
                      disabled
                    />
                  </div>
           </div>
           <div class="field">
                <label class="label field-label">{{ $t('approval_end') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.approval_end"
                    disabled
                  />
                </div>
          </div>
           <div class="field">
                  <label class="label field-label">{{ $t('planned_start') }}</label>
                  <div class="control">
                    <input
                      class="input dashboard_input"
                      type="text"
                      placeholder="-"
                      v-model="vemags.planned_start"
                      disabled
                    />
                  </div>
           </div>
           <div class="field">
                <label class="label field-label">{{ $t('planned_end') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.planned_end"
                    disabled
                  />
                </div>
          </div>
           <div class="field">
              <label class="label field-label">{{ $t('start_date') }}</label>
              <div class="control">
                <input
                  class="input dashboard_input"
                  type="text"
                  placeholder="-"
                  v-model="vemags.start_date"
                  disabled
                />
              </div>
           </div>
           <div class="field">
                <label class="label field-label">{{ $t('end_date') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.end_date"
                    disabled
                  />
                </div>
          </div>
           <div class="field">
              <label class="label field-label">{{ $t('from') }}</label>
              <div class="control">
                <input
                  class="input dashboard_input"
                  type="text"
                  placeholder="-"
                  v-model="vemags.from"
                  disabled
                />
              </div>
          </div>
         
          <div class="field">
                <label class="label field-label">{{ $t('to') }}</label>
                <div class="control">
                  <input
                    class="input dashboard_input"
                    type="text"
                    placeholder="-"
                    v-model="vemags.to"
                    disabled
                  />
                </div>
          </div>

           <!-- END'S HERE -->

    
          </div>
          <!-- Left column ends here -->

          <div class="column is-one-third">
            <!-- Right columns -->

            <span class="certified-co-pilot">
              <p>
                <img src="logo.png" class="right-image" />
              </p>
              <p>
                <img src="text_de.png" />
              </p>
            </span>

            <div class="erna-status erna-valid" v-if="vemags.erna_valid">
              <font-awesome-icon icon="check" />
              <p>{{ $t('erna_active') }}</p>
            </div>
            <div class="erna-status erna-invalid" v-else>
              <font-awesome-icon icon="exclamation-triangle" />
              <p>{{ $t('erna_inactive') }}</p>
            </div>
          </div>
          <!-- Right columns -->
        </div>
        <!-- Columns holder ends here -->
      </section>
    </div>

    <footer class="footer" id="beifahrer-footer">
      <div class="buttons are-small form-buttons">
        <router-link class="button is-rounded footer_btn" to="/impressum">{{ $t('impressum_txt') }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/datenschuz">{{ $t('datenschutz_txt') }}</router-link>
        <router-link class="button is-rounded footer_btn" to="/conditions">{{ $t('agb_txt') }}</router-link>
      </div>
    </footer>
  </div>
  <!-- form container ends here -->
</template>

<script>
export default {
  data() {
    return {
      authenticated: false,
      vemags: "",
      isActive: true,
      erna_active: "fas fa-check",
    };
  },
  methods: {},
  mounted() {

    this.$i18n.locale = this.$route.params.lang;

    if (this.$route.params.authenticated == true) {
      this.authenticated = this.$route.params.authenticated;
      this.vemags = this.$route.params.vemag;
    }

    if (this.authenticated == false) {
      this.$router.push({ name: "FormEn" });
    }
  },
};
</script>
