import VueAxios from "vue-axios";
import axios from "axios";

window.axios = require("axios");
axios.defaults.withCredentials = true;

const domain = "/";
// const domain = "http://localhost:8000/";

let api = {
  beifahrer: function (vemags, pin , fahrtwegteil) {
    return axios
      .get(domain+"tour/check_erna/?vemags_nb=" + vemags + "&pin=" + pin + "&fahrtwegteil=" + fahrtwegteil, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export default api;
